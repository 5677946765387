<template>
    <div class="slm-bubble-carousel__post">
        <div class="slm-bubble-carousel__post--bubble">
            <div class="slm-bubble-carousel__post--image-wrapper" v-html="post.image">
            </div>
        </div>

        <div class="slm-bubble-carousel__post--info">
            <h2 class="slm-bubble-carousel__post--title">{{post.title}}</h2>
            <p class="slm-bubble-carousel__post--description">{{post.description}}</p>
            <a class="slm-bubble-carousel__post--link slm-link-button slm-link-button__alternative" :target="post.target" :href="post.url">{{post.linkText}}</a>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'post-component',
    props: ['post'],
}
</script>